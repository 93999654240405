import { useMemo } from "react";
import { t } from "@/i18n-js/instance";
import { MapboxInputFilter } from "@circle-react-shared/Filter/FilterTypes/MapboxInputFilter";
import type { MapBoxFilterParams } from "@circle-react-shared/Filter/FilterTypes/MapboxInputFilter";
import { Filter } from "@circle-react-shared/FilterV3";
import type { BaseFilter } from "../types";

const localeNamespace = "members_directory.header.search.filters.geo_location";

interface SegmentFilterProps {
  isInitialOpen?: boolean;
  onApply: (value: BaseFilter | null) => void;
  param: MapBoxFilterParams;
}

export const GeoLocationFilter = ({
  isInitialOpen = false,
  onApply,
  param,
}: SegmentFilterProps) => {
  const FilterChip = useMemo(
    () => (
      <Filter.Chip renderSelectedValue={() => param?.value?.name || ""}>
        {t([localeNamespace])}
      </Filter.Chip>
    ),
    [param],
  );

  return (
    <Filter
      onApply={value => {
        onApply?.(value);
      }}
    >
      {FilterChip}
      <Filter.Popover
        title={t([localeNamespace])}
        isInitialOpen={isInitialOpen}
      >
        <MapboxInputFilter param={param} />
      </Filter.Popover>
    </Filter>
  );
};
