import type { RenderFilter } from "./types";
import { useCrmGeolocationEnabled } from "./useCrmGeolocationEnabled";

export const useShouldShowGeolocationFilter = (filters: RenderFilter[]) => {
  const isCrmGeolocationEnabled = useCrmGeolocationEnabled();

  if (isCrmGeolocationEnabled) {
    return filters;
  }

  return filters.filter(filter => filter.name !== "geo_location");
};
