import { t } from "@/i18n-js/instance";
import type { LocationOption } from "@/react/helpers/mapboxHelper";
import { useButtonVariant } from "@/react/helpers/useButtonVariant";
import { useMapbox } from "@/react/hooks/useMapbox";
import { useFilterContext } from "@circle-react-shared/FilterV3/FilterContext";
import type { BaseFilter } from "@circle-react-shared/MemberFilters/types";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";
import { TextSelect } from "../../TextSelect";
import { BaseFilterFields } from "./BaseFilterFields";

export interface MapBoxFilterParams extends BaseFilter {
  value?: {
    id: string;
    name: string;
    source: string;
  };
}

export function MapboxInputFilter({ param }: { param: MapBoxFilterParams }) {
  const {
    locationOptions,
    handleSuggestionSelect,
    selectedMapboxId,
    debouncedSearch,
    selectedLocation,
  } = useMapbox();

  const { onApply } = useFilterContext();
  const primaryButtonVariant = useButtonVariant();

  const getDefaultValues = () => ({
    ...param,
    value: param.value,
  });

  const handleApply = (submitData: MapBoxFilterParams) => {
    if (selectedLocation) {
      onApply({
        ...submitData,
        value: {
          id: selectedLocation.mapbox_id,
          name: selectedLocation.name,
          source: "mapbox_v6",
        },
      });
    }
  };

  return (
    <div className="flex flex-col gap-y-3 py-4">
      <Form defaultValues={getDefaultValues()} onSubmit={handleApply}>
        <div className="flex flex-col gap-y-4 px-4">
          <TextSelect
            placeholder={t("filters.location_filter.search_location")}
            emptyMessage={t("filters.location_filter.no_locations_found")}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any -- needs more ts conversions
            options={locationOptions as any}
            onChange={handleSuggestionSelect}
            onSearchChange={debouncedSearch}
            value={selectedMapboxId}
            displayValue={(option: LocationOption) => option?.label || ""}
          />

          <BaseFilterFields />

          <Button variant={primaryButtonVariant} type="submit" full>
            {t("filters.apply")}
          </Button>
        </div>
      </Form>
    </div>
  );
}
